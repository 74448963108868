import _isEmpty from 'lodash/isEmpty'
import { useTranslation } from 'react-i18next'
import { useWindowSize } from '@uidotdev/usehooks'

import * as H from 'hooks'
import { GroupMode } from 'types/ui'
import GroupList from 'components/GroupList/GroupList'
import PolygonList from 'components/PolygonList/PolygonList'
import NoDataSkeleton from 'components/NoDataSkeleton/NoDataSkeleton'
import {
  type Emissions,
  type EmissionsGroup,
  type EmissionsFilters
} from 'types/data'

import { CLASS_NAME } from './const'

import './style.scss'

const GROUP_LIST_ITEM_WIDTH_PX = 430

type Props = {
  className?: string
  emissions: Emissions[]
  filters: EmissionsFilters
  selectedEmissions: Emissions[]
  selectedGroups: EmissionsGroup[]
  groupedEmissions: EmissionsGroup[]
  setFilterValue: (key: string, value: any) => void
  onShowFeatureInfo: (emissions: Emissions) => void
  setSelectedEmissions: (emissions: Emissions[]) => void
}

export default function InsightsSidebar({
  filters,
  emissions,
  className,
  selectedGroups,
  groupedEmissions,
  selectedEmissions,
  onShowFeatureInfo,
  setSelectedEmissions
}: Props) {
  const { groupMode } = filters
  const { t } = useTranslation()
  const { height: windowHeight } = useWindowSize()
  const finalClassName = H.useClassName(CLASS_NAME, className, {
    grouped: groupMode !== GroupMode.None
  })

  return (
    <div className={finalClassName}>
      {emissions.length === 0 && <NoDataSkeleton />}
      {emissions.length > 0 && groupMode === GroupMode.None && (
        <PolygonList
          title={t('analytics_sidebar.feature_list_title')}
          emissions={emissions}
          onShowFeatureInfo={onShowFeatureInfo}
          selectedEmissions={selectedEmissions}
          setSelectedEmissions={setSelectedEmissions}
          scrollWindowWidth={508}
          scrollWindowHeight={windowHeight - 232}
          subTitle={
            _isEmpty(selectedEmissions)
              ? t('analytics_sidebar.feature_list_subtitle_empty')
              : `${
                  selectedEmissions?.length ??
                  t('analytics_sidebar.feature_list_subtitle_selected_none')
                } ${t(
                  'analytics_sidebar.feature_list_subtitle_selected_suffix'
                )}`
          }
          showSelectionIndex
        />
      )}

      {emissions.length > 0 && groupMode !== GroupMode.None && (
        <GroupList
          setSelectedEmissions={setSelectedEmissions}
          selectedEmissions={selectedEmissions}
          itemWidthPX={GROUP_LIST_ITEM_WIDTH_PX}
          groups={groupedEmissions}
          scrollWindowWidth={508}
          scrollWindowHeight={windowHeight - 232}
          subTitle={
            _isEmpty(selectedGroups)
              ? t('analytics_sidebar.group_list_subtitle_empty')
              : `${
                  selectedGroups?.length ??
                  t('analytics_sidebar.group_list_subtitle_selected_none')
                } ${t('analytics_sidebar.group_list_subtitle_selected_suffix')}`
          }
          showSelectionIndex
        />
      )}
    </div>
  )
}

export { CLASS_NAME }
