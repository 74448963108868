import { useTranslation } from 'react-i18next'

import * as U from 'utils'
import * as H from 'hooks'
import DashboardCard, { TextColor } from '../DashboardCard/DashboardCard'

import { CLASS_NAME } from './const'
import { type Emissions } from 'types/data'

import '../style.scss'

type Props = {
  emissions: Emissions[]
  year: number
  className?: string
}

export default function NEETotal({
  emissions,
  year,
  className
}: Props): JSX.Element {
  const { t } = useTranslation()
  const finalClassName = H.useClassName(CLASS_NAME, className)
  const total = U.getEmissionsTotalForYear(emissions, year)
  const finalValue = U.getFormattedEmissionsValue(total)
  const getTextColor = (): TextColor | undefined => {
    if (total > 0) return TextColor.Red
    if (total < 0) return TextColor.Green
  }

  return (
    <DashboardCard
      title={t('metric_card_annual_flux.title')}
      tooltipText={t('metric_card_annual_flux.tooltip')}
      value={finalValue}
      textColor={getTextColor()}
      className={finalClassName}
    />
  )
}

export { CLASS_NAME }
