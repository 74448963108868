import { useEffect } from 'react'
import mapboxgl, { type Map } from 'mapbox-gl'
import _isNil from 'lodash/isNil'
import _isUndefined from 'lodash/isUndefined'

import { getLogger } from 'logger'
import { type Emissions } from 'types/data'

const { NODE_ENV } = process.env
const l = getLogger('pages:map:hooks:use-selected-emissions-map-markers')

export default function useCenterPolygons(
  map: Map | undefined,
  selectedEmissions: Emissions[],
  filteredEmissions: Emissions[],
  onMarkerClick: (emissions: Emissions) => void
): void {
  useEffect((): (() => void) | undefined => {
    if (
      _isNil(map) ||
      _isUndefined(map) ||
      _isUndefined((map as any).style) ||
      filteredEmissions.length === 0
    ) {
      return
    }

    const hasSelectedEmissions = selectedEmissions.length > 0
    const emissions = hasSelectedEmissions
      ? selectedEmissions
      : filteredEmissions
    const emissionsPointCoordinates = emissions.map(
      ({ feature }) => feature.geometry.coordinates as unknown as number[]
    )
    const minLng = Math.min(...emissionsPointCoordinates.map(([lng]) => lng))
    const maxLng = Math.max(...emissionsPointCoordinates.map(([lng]) => lng))
    const minLat = Math.min(...emissionsPointCoordinates.map(([, lat]) => lat))
    const maxLat = Math.max(...emissionsPointCoordinates.map(([, lat]) => lat))

    const bounds = new mapboxgl.LngLatBounds([
      [minLng, minLat],
      [maxLng, maxLat]
    ])

    try {
      map.fitBounds(bounds, {
        padding: 100,
        maxZoom: 13,
        duration: hasSelectedEmissions ? 2000 : 0
      })
    } catch (err: any) {
      if (NODE_ENV === 'development') {
        l.error((err as Error).stack)
      } else {
        l.error((err as Error).message)
      }
    }
  }, [onMarkerClick, selectedEmissions, filteredEmissions, map])
}
