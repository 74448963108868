import {
  useState,
  useEffect,
  useRef,
  useCallback,
  type MutableRefObject
} from 'react'
import mapboxgl from 'mapbox-gl'
import _isUndefined from 'lodash/isUndefined'
import _isNil from 'lodash/isNil'
import { type LngLatBoundsLike, type Map as MapBox } from 'mapbox-gl'

import useClustersLayer from '../../pages/insights/map/hooks/useClustersLayer'
import useHandleClusterClick from '../../pages/insights/map/hooks/useHandleClusterClick'
import usePolygonsLayer from '../../pages/insights/map/hooks/usePolygonsLayer'
import useHandlePolygonClick from '../../pages/insights/map/hooks/useHandlePolygonClick'
import useCenterPolygons from '../../pages/insights/map/hooks/useCenterPolygons'
import useSetMarkersVisibleOnMapZoom from '../../pages/insights/map/hooks/useSetMarkersVisibleOnMapZoom'
import useSelectedEmissionsMarkers from '../../pages/insights/map/hooks/useSelectedEmissionsMarkers'
import genGeoJSON from '../../../src/utils/data/gen_geo_json'
import { type GeoJSON, type Emissions } from '../../types/data'
import handleFeatureClick from '../../pages/insights/map/utils/handleFeatureClick'
import { type IGBPLandCoverClass } from 'types'

import 'mapbox-gl/dist/mapbox-gl.css'

import * as H from '../../hooks'
import { CLASS_NAME } from './const'

import './style.scss'

const { REACT_APP_MAPBOX_ACCESS_TOKEN } = process.env

if (!_isUndefined(REACT_APP_MAPBOX_ACCESS_TOKEN)) {
  mapboxgl.accessToken = REACT_APP_MAPBOX_ACCESS_TOKEN
}

type Props = {
  className?: string
  filteredEmissions: Emissions[]
  selectedEmissions: Emissions[]
  setMarkersVisible: (markersVisible: boolean) => void
  selectedPolygonUUIDs: string[]
  setSelectedEmissions: (selectedEmissions: Emissions[]) => void
}

export default function Map({
  className,
  filteredEmissions,
  selectedEmissions,
  selectedPolygonUUIDs,
  setSelectedEmissions,
  setMarkersVisible
}: Props) {
  const mapContainerRef = useRef<HTMLDivElement>(null)
  const [initializedMap, setInitializedMap] = useState<boolean>(false)
  const mapRef = useRef<MapBox>()
  const [stateMapRef, setStateMapRef] =
    useState<MutableRefObject<MapBox | undefined>>(mapRef)
  const finalClassName = H.useClassName(CLASS_NAME, className as string)
  const geoJsonWithPolygonGeometry = H.useFunctionMemo<GeoJSON>(
    genGeoJSON,
    filteredEmissions,
    true
  )
  const geoJsonWithPointGeometry = H.useFunctionMemo<GeoJSON>(
    genGeoJSON,
    filteredEmissions
  )

  useEffect((): (() => void) | undefined => {
    if (initializedMap || mapContainerRef.current === null) {
      return
    }

    setInitializedMap(true)

    const map = new mapboxgl.Map({
      container: mapContainerRef.current as HTMLElement,
      style: 'mapbox://styles/mapbox/light-v10'
    })

    map.addControl(new mapboxgl.NavigationControl())

    map.on('style.load', (): void => {
      setStateMapRef({ current: map })
    })

    return (): void => {
      try {
        stateMapRef?.current?.remove()
      } catch (error) {
        console.error(error)
      }
    }
  }, [mapContainerRef.current, initializedMap])

  const onMarkerClick = useCallback(
    (e: Emissions) => {
      if (
        _isNil(stateMapRef.current) ||
        _isUndefined(stateMapRef.current) ||
        _isUndefined((stateMapRef.current as any).style)
      ) {
        return
      }

      const { feature } = e
      const { geometry } = feature
      const { coordinates } = geometry

      const bounds = [coordinates, coordinates] as unknown as LngLatBoundsLike

      if (stateMapRef.current !== null) {
        stateMapRef.current.fitBounds(bounds, {
          padding: 100,
          maxZoom: 13,
          duration: 2000
        })
      }
    },
    [stateMapRef.current]
  )

  const onFeatureClick = useCallback(
    (featureUUID: string, featureLCC: IGBPLandCoverClass) => {
      handleFeatureClick(
        filteredEmissions,
        selectedEmissions,
        setSelectedEmissions,
        featureUUID,
        featureLCC
      )
    },
    [filteredEmissions, selectedEmissions, setSelectedEmissions]
  )

  useCenterPolygons(
    stateMapRef.current,
    selectedEmissions,
    filteredEmissions,
    onMarkerClick
  )
  usePolygonsLayer(stateMapRef.current, geoJsonWithPolygonGeometry)
  useClustersLayer(
    stateMapRef.current,
    geoJsonWithPointGeometry,
    selectedEmissions
  )
  useSelectedEmissionsMarkers(
    stateMapRef.current,
    selectedEmissions,
    filteredEmissions,
    onMarkerClick
  )
  useHandleClusterClick(stateMapRef.current)
  useHandlePolygonClick(
    onFeatureClick,
    stateMapRef.current,
    selectedPolygonUUIDs
  )
  useSetMarkersVisibleOnMapZoom(stateMapRef.current, setMarkersVisible)

  return <div className={finalClassName} ref={mapContainerRef} />
}

export { CLASS_NAME }
