import { Trans } from 'react-i18next'
import { Tooltip } from 'react-tooltip'
import cn from 'clsx'

import * as H from '../../../hooks'
import { CLASS_NAME } from './const'

import './style.scss'

export enum TextColor {
  Green = 'green',
  Red = 'red'
}

type Props = {
  title: string
  value: string
  tooltipText?: string
  className?: string
  textColor?: TextColor
}

export default function DashboardCard({
  title,
  value,
  tooltipText,
  className,
  textColor
}: Props): JSX.Element {
  const finalClassName = H.useClassName(CLASS_NAME, className)

  return (
    <div className={finalClassName}>
      <div className={`${CLASS_NAME}-title`}>
        <Tooltip
          anchorSelect={`.${className}-tooltip`}
          place="top"
          noArrow
          className="tooltip"
        >
          {tooltipText}
        </Tooltip>
        <Trans
          i18nKey={title}
          components={{
            1: <span className={`${className}-tooltip has-tooltip`} />
          }}
        />
      </div>
      <div className={cn(`${CLASS_NAME}-value`, textColor)}>{value}</div>
    </div>
  )
}

export { CLASS_NAME }
