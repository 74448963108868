import { useEffect } from 'react'
import _isUndefined from 'lodash/isUndefined'
import { type AnyLayer, type Map } from 'mapbox-gl'

import { addMapLayer, addMapSource, removeMapLayer } from '../utils/utils'

import { type Emissions, type GeoJSON } from '../../../../types/data'

import {
  FEATURE_POINTS_SOURCE,
  CLUSTER_MAP_SOURCE_CONFIG,
  CLUSTERS_LAYER_CONFIG,
  CLUSTER_COUNT_LAYER_CONFIG,
  POINTS_LAYER_CONFIG,
  CLUSTERS_LAYER_ID,
  POINT_COUNT_LAYER_CONFIG
} from '../const'

export default function useClusterLayer(
  map?: Map,
  geoJson?: GeoJSON,
  selectedEmissions?: Emissions[]
): void {
  useEffect((): void => {
    if (
      _isUndefined(map) ||
      _isUndefined(geoJson) ||
      _isUndefined((map as any).style) ||
      _isUndefined(selectedEmissions)
    ) {
      return
    }

    addMapSource(map, FEATURE_POINTS_SOURCE, geoJson, CLUSTER_MAP_SOURCE_CONFIG)

    if (selectedEmissions.length === 0) {
      addMapLayer(map, CLUSTERS_LAYER_CONFIG)
      addMapLayer(map, CLUSTER_COUNT_LAYER_CONFIG)
      addMapLayer(map, POINTS_LAYER_CONFIG as AnyLayer, CLUSTERS_LAYER_ID)
      addMapLayer(map, POINT_COUNT_LAYER_CONFIG, CLUSTERS_LAYER_ID)
    } else {
      removeMapLayer(map, CLUSTERS_LAYER_CONFIG)
      removeMapLayer(map, CLUSTER_COUNT_LAYER_CONFIG)
      removeMapLayer(map, POINTS_LAYER_CONFIG as AnyLayer)
      removeMapLayer(map, POINT_COUNT_LAYER_CONFIG)
    }
  }, [selectedEmissions, map, (map as any)?.style, geoJson])
}
