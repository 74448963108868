import {
  useCallback,
  useMemo,
  type CSSProperties,
  type SyntheticEvent
} from 'react'
import cn from 'clsx'
import _noop from 'lodash/noop'
import _isEmpty from 'lodash/isEmpty'
import { Trans } from 'react-i18next'
import _isFinite from 'lodash/isFinite'
import _isUndefined from 'lodash/isUndefined'
import { useMeasure } from '@uidotdev/usehooks'
import { Tooltip } from 'react-tooltip'

import * as H from '../../hooks'
import * as U from '../../utils'
import IconInfo from '../IconInfo/IconInfo'

import { type Emissions } from '../../types/data'

import './style.scss'

const DEFAULT_POLYGON_NAME_CONTAINER_WIDTH = 20
// approximately the average width of a character is about half of the font size for sans-serif fonts, this is the value for 14px font size
const AVERAGE_CHARACTER_WIDTH = 7
const CLASS_NAME = 'cst-feature-list-item'

type Props = {
  className?: string
  style?: CSSProperties
  active?: boolean
  selected?: boolean
  emissions: Emissions
  onShowInfo?: () => void
  showSelectionIndex?: boolean
  selectionIndex?: number
  renderID?: boolean
  onClick?: any
  disableSelection?: boolean
}

export default function PolygonListItem({
  showSelectionIndex,
  disableSelection,
  selectionIndex,
  onShowInfo,
  emissions,
  className,
  selected,
  renderID,
  onClick,
  active,
  style
}: Props) {
  const { id, feature, total, totalIntensity } = emissions
  const {
    properties: { feature_uuid: polygonId, name, area }
  } = feature
  const [uiIDContainerRef, { width: uiIDContainerWidth }] = useMeasure()
  const finalClassName = H.useClassName(CLASS_NAME, className, {
    active: active === true,
    selected: selected === true
  })

  const nee = U.getFormattedEmissionsValue(total, false)
  const intensity = _isFinite(totalIntensity)
    ? U.getFormattedEmissionsValue(totalIntensity, true)
    : 0

  const finalUIIDContainerWidth =
    uiIDContainerWidth === 0
      ? DEFAULT_POLYGON_NAME_CONTAINER_WIDTH * AVERAGE_CHARACTER_WIDTH
      : uiIDContainerWidth

  const handleInfoClick = useCallback(
    (e: SyntheticEvent) => {
      if (!_isUndefined(onShowInfo)) {
        onShowInfo()
      }

      e.stopPropagation()
      e.preventDefault()

      return false
    },
    [onShowInfo]
  )

  const polygonName = _isEmpty(name) ? polygonId : name
  // NOTE: This is a hack as text-overflow: ellipsis doesn't work within a
  // flexbox container.
  const polygonDisplayName = useMemo((): string => {
    const displayNameCharacterCount = Math.ceil(
      finalUIIDContainerWidth / AVERAGE_CHARACTER_WIDTH - 4
    )

    return polygonName.length <= displayNameCharacterCount
      ? polygonName
      : `${polygonName.substring(0, displayNameCharacterCount)}...`
  }, [finalUIIDContainerWidth, polygonId, name])

  // prettier-ignore
  return (
    <div
      style={style}
      onClick={disableSelection === true ? _noop : onClick}
      className={cn(finalClassName, {
        clickDisabled: disableSelection
      })}
    >
      <div className={`${CLASS_NAME}-wrapper`}>
        {renderID !== false && (
          <div
            ref={uiIDContainerRef}
            className={`${CLASS_NAME}-column ${CLASS_NAME}-column-id`}
          >
            <p>
              <Trans i18nKey="feature_list_item.id_column_label">ID</Trans>
            </p>
            <p>{id}</p>
          </div>
        )}
          <div ref={uiIDContainerRef} className={`${CLASS_NAME}-column name`}>
            <p>
              <Trans i18nKey="feature_list_item.name_column_label">Name</Trans>
            </p>
            <Tooltip anchorSelect={`.${CLASS_NAME}-id`} place='top-start' noArrow>{polygonName}</Tooltip>
            <p className={`${CLASS_NAME}-id`}>{polygonDisplayName}</p>
          </div>
        <div className={`${CLASS_NAME}-column ${CLASS_NAME}-column-area`}>
          <p>
            <Trans i18nKey="feature_list_item.area_column_label">Area</Trans>
          </p>
          <p>{U.formatUINumber(area)} HA</p>
        </div>
        <div
          className={cn(`${CLASS_NAME}-column ${CLASS_NAME}-column-intensity`, {
            positive: totalIntensity !== null && totalIntensity > 0,
            negative: totalIntensity !== null && totalIntensity < 0
          })}
        >
          <p>
            <Trans i18nKey="feature_list_item.intensity_column_label">
              Intensity
            </Trans>
          </p>
          <p>{intensity}</p>
        </div>
        <div
          className={cn(`${CLASS_NAME}-column ${CLASS_NAME}-column-nee`, {
            positive: total > 0,
            negative: total < 0
          })}
        >
          <p>
            <Trans i18nKey="feature_list_item.nee_column_label">NEE</Trans>
          </p>
          <p>{nee}</p>
        </div>
        {!_isUndefined(onShowInfo) && (
          <div
            onClick={handleInfoClick}
            className={cn(
              `${CLASS_NAME}-column`,
              `${CLASS_NAME}-column-info`,
              'icon'
            )}
          >
            <IconInfo />
          </div>
        )}
        {(
          (showSelectionIndex === true) &&
          !_isUndefined(selectionIndex) &&
          (selectionIndex >= 0)
        ) && (
            <div className={`${CLASS_NAME}-selection-index`}>
              <p>{selectionIndex + 1}</p>
            </div>
        )}
      </div>
    </div>
  )
}
