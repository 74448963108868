import cn from 'clsx'
import _noop from 'lodash/noop'
import _isUndefined from 'lodash/isUndefined'
import React, { useMemo, useCallback } from 'react'
import { type ListChildComponentProps, FixedSizeList } from 'react-window'

import * as H from 'hooks'
import { type Emissions } from 'types/data'
import PolygonListItem from 'components/PolygonListItem/PolygonListItem'
import EmissionsListHeader from 'components/EmissionsListHeader/EmissionsFilterHeader'

import './style.scss'

const CLASS_NAME = 'cst-feature-list'

type Props = {
  className?: string
  title?: string
  subTitle?: string
  multiLine?: boolean
  emissions: Emissions[]
  selectedEmissions?: Emissions[]
  multiLineItems?: boolean
  showSelectionIndex?: boolean
  disableSelection?: boolean
  setSelectedEmissions?: (emissions: Emissions[]) => void
  onShowFeatureInfo?: (emissions: Emissions) => void
  renderIDs?: boolean
  scrollWindowWidth?: number
  scrollWindowHeight?: number
}

export default function PolygonList({
  title,
  subTitle,
  emissions,
  className,
  renderIDs,
  disableSelection,
  onShowFeatureInfo,
  selectedEmissions,
  showSelectionIndex,
  setSelectedEmissions,
  scrollWindowWidth,
  scrollWindowHeight
}: Props) {
  const finalClassName = H.useClassName(CLASS_NAME, className)
  const emissionsSelectionStatuses = useMemo(
    (): boolean[] =>
      emissions.map(
        (e: Emissions): boolean =>
          !_isUndefined(selectedEmissions?.find(({ id }) => id === e.id))
      ),
    [emissions, selectedEmissions]
  )

  const {
    onSelectEmissions,
    onDeselectEmissions,
    onSelectAll,
    onDeselectAll,
    isSelectAllDisabled,
    isDeselectAllDisabled
  } = H.useEmissionsSelect(emissions, selectedEmissions, setSelectedEmissions)

  const onShowFeatureInfoClick = useCallback(
    (e: Emissions) => {
      if (!_isUndefined(onShowFeatureInfo)) {
        onShowFeatureInfo(e)
      }
    },
    [onShowFeatureInfo]
  )

  const FeatureListRow: React.FC<ListChildComponentProps> = (
    props: ListChildComponentProps
  ): JSX.Element => {
    const { index, style, data } = props
    const e: Emissions = data[index]
    const finalStyle = {
      ...style,
      top: +(style?.top ?? 0) + 16,
      left: +(style?.left ?? 0) + 32,
      width: Math.max(0, (scrollWindowWidth ?? 0) - 64)
    }

    return (
      <li
        style={finalStyle}
        onClick={
          disableSelection === true
            ? _noop
            : emissionsSelectionStatuses[index]
              ? onDeselectEmissions?.bind(null, e) ?? _noop
              : onSelectEmissions?.bind(null, e) ?? _noop
        }
      >
        <PolygonListItem
          emissions={e}
          renderID={renderIDs}
          disableSelection={disableSelection}
          showSelectionIndex={showSelectionIndex}
          selected={emissionsSelectionStatuses[index]}
          onShowInfo={onShowFeatureInfoClick?.bind(null, e)}
          selectionIndex={(selectedEmissions ?? []).findIndex(
            ({ id }) => id === e.id
          )}
        />
      </li>
    )
  }

  // prettier-ignore
  return (
    <div className={cn(finalClassName)}>
      {disableSelection !== true && (
        <EmissionsListHeader
          title={title}
          subTitle={subTitle}
          onSelectAll={onSelectAll}
          onDeselectAll={onDeselectAll}
          isSelectAllEnabled={!isSelectAllDisabled}
          isDeselectAllEnabled={!isDeselectAllDisabled}
        />
      )}

      <ul className={`${CLASS_NAME}-list`}>
        <FixedSizeList
          itemSize={80}
          itemData={emissions}
          itemCount={emissions.length}
          width={scrollWindowWidth ?? 0}
          height={scrollWindowHeight ?? 0}
        >
          {FeatureListRow}
        </FixedSizeList>
      </ul>
    </div>
  )
}
