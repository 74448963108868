import React, { useEffect } from 'react'
import ReactDOM from 'react-dom/client'
import { Marker, type Map, type LngLatLike } from 'mapbox-gl'
import _isNil from 'lodash/isNil'
import _isUndefined from 'lodash/isUndefined'

import MapEmissionsMarker from 'components/MapEmissionsMarker/MapEmissionsMarker'
import { type Emissions } from 'types/data'

export default function useSelectedEmissionsMarkers(
  map: Map | undefined,
  selectedEmissions: Emissions[],
  filteredEmissions: Emissions[],
  onMarkerClick: (emissions: Emissions) => void
): void {
  useEffect((): (() => void) | undefined => {
    if (
      _isNil(map) ||
      _isUndefined(map) ||
      _isUndefined((map as any).style) ||
      filteredEmissions.length === 0
    ) {
      return
    }

    let mapMarkers: Marker[] = []

    mapMarkers = selectedEmissions.map((e: Emissions, i: number) => {
      const { feature } = e
      const markerContainer = document.createElement('div')
      const root = ReactDOM.createRoot(markerContainer)

      root.render(
        React.createElement(MapEmissionsMarker, {
          emissions: e,
          selectionIndex: i,
          onClick: onMarkerClick.bind(null, e)
        })
      )

      const { geometry } = feature
      const { coordinates } = geometry
      const marker = new Marker({
        element: markerContainer
      })

      marker.setLngLat(coordinates as unknown as LngLatLike)
      marker.addTo(map)

      return marker
    })

    return (): void => {
      mapMarkers.forEach((marker: Marker) => marker.remove())
    }
  }, [onMarkerClick, selectedEmissions, filteredEmissions, map])
}
