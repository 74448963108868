import { type PaddingOptions, type AnyLayer } from 'mapbox-gl'

const LAYER_FEATURE_STATE_PROP = 'feature-state'

export const CLASS_NAME = 'cst-page-map'
export const PATH = '/map'

export const FEATURES_SOURCE_ID = 'FEATURES_SOURCE_ID'
export const FEATURES_LAYER_ID = 'FEATURES_LAYER_ID'
export const MARKERS_LAYER_ID = 'MARKERS_LAYER_ID'
export const FEATURES_BORDERS_LAYER_ID = 'FEATURES_BORDERS_LAYER_ID'

export const FEATURE_POINTS_SOURCE = 'FEATURE_POINTS_SOURCE'
export const CLUSTER_COUNT_LAYER_ID = 'CLUSTER_COUNT_LAYER_ID'
export const CLUSTERS_LAYER_ID = 'CLUSTERS_LAYER_ID'
export const POINTS_LAYER_ID = 'POINTS_LAYER_ID'
export const POINT_COUNT_LAYER_ID = 'POINT_COUNT_LAYER_ID'

export const PADDING: PaddingOptions = {
  top: 80,
  bottom: 80,
  left: 500,
  right: 80
}

export const MAX_ZOOM = 13

export const CLUSTER_MAP_SOURCE_CONFIG = {
  cluster: true,
  clusterMaxZoom: MAX_ZOOM,
  clusterRadius: 220,
  generateId: true
}

const POINT_LAYER_OPTIONS = {
  source: FEATURE_POINTS_SOURCE,
  maxzoom: MAX_ZOOM
}

const CLUSTER_COUNT_LAYOUT_OPTIONS = {
  'text-size': 18,
  'text-line-height': 18,
  'text-font': ['Lato Bold']
}

const POINT_PAINT_OPTIONS = {
  'circle-color': '#040444',
  'circle-radius': 20,
  'circle-stroke-width': 2,
  'circle-stroke-color': '#fff',
  'circle-opacity': 1
}

export const CLUSTERS_LAYER_CONFIG: AnyLayer = {
  id: CLUSTERS_LAYER_ID,
  type: 'circle',
  source: FEATURE_POINTS_SOURCE,
  filter: ['has', 'point_count'],
  paint: POINT_PAINT_OPTIONS
}

export const CLUSTER_COUNT_LAYER_CONFIG: AnyLayer = {
  id: CLUSTER_COUNT_LAYER_ID,
  type: 'symbol',
  source: FEATURE_POINTS_SOURCE,
  filter: ['has', 'point_count'],
  paint: {
    'text-color': '#fff'
  },
  layout: {
    ...CLUSTER_COUNT_LAYOUT_OPTIONS,
    'text-field': '{point_count_abbreviated}',
    'text-allow-overlap': true
  }
}

export const POINTS_LAYER_CONFIG: any = {
  id: POINTS_LAYER_ID,
  type: 'circle',
  paint: POINT_PAINT_OPTIONS,
  ...POINT_LAYER_OPTIONS
}

export const POINT_COUNT_LAYER_CONFIG: AnyLayer = {
  id: POINT_COUNT_LAYER_ID,
  type: 'symbol',
  layout: {
    ...CLUSTER_COUNT_LAYOUT_OPTIONS,
    'text-field': '1',
    'text-allow-overlap': true
  },
  paint: {
    'text-color': '#fff'
  },
  ...POINT_LAYER_OPTIONS
}

export const POLYGONS_LAYER_OPTIONS: AnyLayer = {
  id: FEATURES_LAYER_ID,
  type: 'fill',
  source: FEATURES_SOURCE_ID,
  layout: {},
  paint: {
    'fill-opacity': [
      'case',
      ['boolean', [LAYER_FEATURE_STATE_PROP, 'isActive'], false],
      1,
      0.3
    ],
    'fill-color': [
      'case',
      ['boolean', [LAYER_FEATURE_STATE_PROP, 'isEmission'], false],
      'red',
      'green'
    ]
  }
}

export const POLYGONS_BORDERS_LAYER_OPTIONS: AnyLayer = {
  id: FEATURES_BORDERS_LAYER_ID,
  type: 'line',
  source: FEATURES_SOURCE_ID,
  layout: {},
  paint: {
    'line-color': [
      'case',
      ['boolean', [LAYER_FEATURE_STATE_PROP, 'isEmission'], false],
      'red',
      'green'
    ],
    'line-width': [
      'case',
      ['boolean', [LAYER_FEATURE_STATE_PROP, 'isHovered'], false],
      3,
      1
    ],
    'line-opacity': 0.8
  }
}
