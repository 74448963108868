import cn from 'clsx'
import _sum from 'lodash/sum'
import _isUndefined from 'lodash/isUndefined'
import { Trans, useTranslation } from 'react-i18next'
import React, { type MouseEvent, useState, useCallback } from 'react'
import { type ListChildComponentProps, FixedSizeList } from 'react-window'

import * as U from 'utils'
import * as H from 'hooks'
import { type Emissions } from 'types/data'
import PolygonListItem from 'components/PolygonListItem/PolygonListItem'

import { CLASS_NAME } from './const'
import { type GroupListItemProps } from './types'

import './style.scss'

const GroupListItem: React.FC<GroupListItemProps> = (
  props: GroupListItemProps
) => {
  const {
    setSelectedEmissions,
    showSelectionIndex,
    selectedEmissions,
    selectionIndex,
    className,
    selected,
    width,
    group,
    onClick
  } = props

  const { items, key } = group
  const { t } = useTranslation()
  const finalClassName = H.useClassName(CLASS_NAME, className)
  const [isExpanded, setIsExpanded] = useState<boolean>(false)
  const groupTotalArea = _sum(
    items.map(({ feature }: { feature: any }) => feature?.properties?.area ?? 0)
  )

  const groupTotalNEE = _sum(items.map(({ total }: { total: number }) => total))
  const groupIntensity = groupTotalNEE / groupTotalArea
  const onToggleExpand = useCallback(
    (e: MouseEvent) => {
      setIsExpanded(!isExpanded)

      e.stopPropagation()
      e.preventDefault()

      return false
    },
    [isExpanded]
  )

  const handleListItemClick = useCallback(
    (emissions: Emissions, e: MouseEvent): boolean => {
      e.preventDefault()
      e.stopPropagation()

      const { id: eID } = emissions
      const isFeatureSelected = !_isUndefined(
        selectedEmissions.find(({ id }) => id === eID)
      )

      if (isFeatureSelected) {
        const newSelectedEmissions = selectedEmissions.filter(
          ({ id }) => id !== eID
        )
        setSelectedEmissions(newSelectedEmissions)
      } else {
        setSelectedEmissions([...selectedEmissions, emissions])
      }

      return false
    },
    [selectedEmissions, setSelectedEmissions]
  )

  const FeatureListRow: React.FC<ListChildComponentProps> = (
    props: ListChildComponentProps
  ): JSX.Element => {
    const { index, style, data } = props
    const item: Emissions = data[index]
    const isSelected = !_isUndefined(
      selectedEmissions.find(({ id }) => id === item.id)
    )

    const finalStyle = {
      ...style,
      top: +(style?.top ?? 0),
      left: +(style?.left ?? 0) + 16,
      width: Math.max(0, +(width ?? 0) - 16)
    }

    return (
      <li style={finalStyle} onClick={handleListItemClick.bind(null, item)}>
        <PolygonListItem emissions={item} selected={isSelected} />
      </li>
    )
  }

  // prettier-ignore
  return (
    <li
      onClick={onClick}
      className={cn(finalClassName, {
        selected,
        expanded: isExpanded
      })}
    >
      <div className={`${CLASS_NAME}-header`}>
        <h3>{key}</h3>
        <p>
          {items.length.toLocaleString()} {t('group_list_item.assets_suffix')}
        </p>

        <p
          className={cn(`${CLASS_NAME}-toggle-expansion`, { selected: isExpanded })}
          onClick={onToggleExpand}
        >
          {isExpanded
            ? t('group_list_item.collapse')
            : t('group_list_item.expand')}
        </p>
      </div>

      {!isExpanded && (
        <ul className={`${CLASS_NAME}-values`}>
          <li className={`${CLASS_NAME}-values-intensity`}>
            <p>
              <Trans i18nKey="group_list_item.intensity_row_label">
                Intensity
              </Trans>
            </p>
            <p
              className={cn({
                negative: groupIntensity < 0,
                positive: groupIntensity >= 0
              })}
            >{U.formatUINumber(groupIntensity)} T/ha</p>
          </li>

          <li className={`${CLASS_NAME}-values-nee`}>
            <p>
              <Trans i18nKey="group_list_item.nee_row_label">Total NEE</Trans>
            </p>
            <p
              className={cn({
                negative: groupTotalNEE < 0,
                positive: groupTotalNEE >= 0
              })}
            >{U.formatUINumber(groupTotalNEE / 1000)} kT</p>
          </li>

          <li className={`${CLASS_NAME}-values-area`}>
            <p>
              <Trans i18nKey="group_list_item.area_row_label">Total Area</Trans>
            </p>
            <p>{U.formatUINumber(groupTotalArea)} Ha</p>
          </li>
        </ul>
      )}

      {isExpanded && (
        <ul className={`${CLASS_NAME}-list`}>
          <FixedSizeList
            height={150}
            itemSize={80}
            width={width}
            itemData={items}
            itemCount={items.length}
          >
            {FeatureListRow}
          </FixedSizeList>
        </ul>
      )}

      {(
        (selected === true) &&
        (showSelectionIndex === true) &&
        (
          (!_isUndefined(selectionIndex)) &&
          (selectionIndex >= 0)
        )
      ) && (
          <div className={`${CLASS_NAME}-selection-index`}>
            <p>{selectionIndex + 1}</p>
          </div>
      )}
    </li>
  )
}

export default GroupListItem
export { CLASS_NAME }
export type { GroupListItemProps as PROPS }
