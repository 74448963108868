import { useTranslation } from 'react-i18next'
import { useMemo, useCallback } from 'react'

import * as H from 'hooks'
import Dropdown from 'components/Dropdown/Dropdown'
import { SortMode, GroupMode } from 'types/ui'
import SearchInput from 'components/SearchInput/SearchInput'
import { type DropdownOption } from 'components/Dropdown/types'

import { CLASS_NAME } from './const'
import { type EmissionsFilters } from 'types/data'

import './style.scss'
type Props = {
  className?: string
  filters: EmissionsFilters
  setFilterValue: (key: string, value: any) => void
}

export default function InsightsSideBarHeader({
  filters,
  setFilterValue,
  className
}: Props) {
  const { query, groupMode, sortMode } = filters

  const { t } = useTranslation()
  const finalClassName = H.useClassName(CLASS_NAME, className)

  const sortModeOptions = useMemo(
    (): DropdownOption[] => [
      {
        label: t('analytics_sidebar_header.sort_mode_none'),
        value: SortMode.None
      },
      {
        label: t('analytics_sidebar_header.sort_mode_area'),
        value: SortMode.Area
      },
      {
        label: t('analytics_sidebar_header.sort_mode_total_nee'),
        value: SortMode.TotalNEE
      },
      {
        label: t('analytics_sidebar_header.sort_mode_intensity'),
        value: SortMode.Intensity
      }
    ],
    [t]
  )

  const groupModeOptions = useMemo(
    (): DropdownOption[] => [
      {
        label: t('analytics_sidebar_header.group_mode_none'),
        value: GroupMode.None
      },
      {
        label: t('analytics_sidebar_header.group_mode_country'),
        value: GroupMode.Country
      },
      {
        label: t('analytics_sidebar_header.group_mode_name'),
        value: GroupMode.Name
      },
      {
        label: t('analytics_sidebar_header.group_mode_site_name'),
        value: GroupMode.SiteName
      },
      {
        label: t('analytics_sidebar_header.group_mode_land_cover'),
        value: GroupMode.LandCover
      }
    ],
    [t]
  )

  const sortModeOption = H.useSortModeOption(sortMode, sortModeOptions)
  const groupModeOption = H.useGroupModeOption(groupMode, groupModeOptions)

  const onSetGroupingMode = useCallback(
    (option: any) => {
      const { value } = option

      setFilterValue('query', '')
      setFilterValue('groupMode', value)
    },
    [setFilterValue]
  )

  const onSetSortMode = useCallback(
    (option: any) => {
      const { value } = option

      setFilterValue('sortMode', value)
    },
    [setFilterValue]
  )

  return (
    <div className={finalClassName}>
      <SearchInput
        value={query}
        onChange={setFilterValue.bind(null, 'query')}
        placeholder={t('analytics_sidebar_header.search_placeholder')}
      />

      <div className={`${CLASS_NAME}-grouping`}>
        <Dropdown
          inline
          value={groupModeOption}
          onChange={onSetGroupingMode}
          options={groupModeOptions}
          placeholder={t('analytics_sidebar_header.group_dropdown_placeholder')}
        />

        <Dropdown
          inline
          value={sortModeOption}
          onChange={onSetSortMode}
          options={sortModeOptions}
          placeholder={t('analytics_sidebar_header.sort_dropdown_placeholder')}
        />
      </div>
    </div>
  )
}

export { CLASS_NAME }
