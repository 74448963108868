import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useMeasure } from '@uidotdev/usehooks'

import * as U from 'utils'
import * as H from 'hooks'
import Dropdown from 'components/Dropdown/Dropdown'
import YearPicker from 'components/YearPicker/YearPicker'
import { type DropdownOption } from 'components/Dropdown/types'
import { IGBPLandCoverClassNames, type IGBPLandCoverClass } from 'types/common'
import { type Emissions, type EmissionsFilters } from 'types/data'

import { ALL_LCC_OPTION, CLASS_NAME } from './const'

import './style.scss'

type Props = {
  className?: string
  minDate?: Date | null
  maxDate?: Date | null
  filters: EmissionsFilters
  emissions: Emissions[] | null
  emissionsMaxDate?: Date | null
  emissionsMinDate?: Date | null
  setFilterValue: (key: string, value: any) => void
}

export default function InsightsControls({
  minDate,
  maxDate,
  filters,
  emissions,
  className,
  setFilterValue
}: Props) {
  const CURRENT_YEAR = new Date().getUTCFullYear()

  const { t } = useTranslation()
  const [ref, { width }] = useMeasure()
  const isLayoutVertical = useMemo((): boolean => width <= 506, [width])
  const finalClassName = H.useClassName(CLASS_NAME, className, {
    vertical: isLayoutVertical
  })

  const lccClasses = useMemo(
    (): IGBPLandCoverClass[] => U.getEmissionsLandCoverClasses(emissions ?? []),
    [emissions]
  )

  const { lcc, start, end } = filters
  const endYear = end === null ? CURRENT_YEAR : end.getUTCFullYear()
  const startYear = start === null ? CURRENT_YEAR - 5 : start.getUTCFullYear()

  // TODO: Extract
  const lccOptions = useMemo(
    (): DropdownOption[] => [
      ALL_LCC_OPTION,
      ...(lccClasses?.map((lcc) => ({
        label: IGBPLandCoverClassNames[lcc],
        value: lcc
      })) ?? [])
    ],
    [lccClasses]
  )

  const selectedLCCOption = useMemo(
    (): DropdownOption | undefined =>
      lcc === null ? ALL_LCC_OPTION : lccOptions.find((o) => o.value === lcc),
    [lccOptions, lcc]
  )

  const onChangeLCC = useCallback(
    (option: DropdownOption): void => {
      const { value } = option

      if (value === ALL_LCC_OPTION.value) {
        setFilterValue('lcc', null)
      } else {
        setFilterValue('lcc', (value as IGBPLandCoverClass) ?? null)
      }
    },
    [setFilterValue]
  )

  const onSetStartYear = useCallback(
    (year: number): void => {
      setFilterValue('start', U.getDateForYear(year))
    },
    [setFilterValue]
  )

  const onSetEndYear = useCallback(
    (year: number): void => {
      setFilterValue('end', U.getDateForYear(year, 11))
    },
    [setFilterValue]
  )

  return (
    <div ref={ref} className={finalClassName}>
      <div className={`${CLASS_NAME}-left`}>
        {minDate !== null && (
          <YearPicker
            value={startYear}
            minDate={minDate}
            maxDate={new Date(`${endYear - 1}`)}
            onChange={onSetStartYear}
            label={t('analytics_controls.start_year_picker_label')}
          />
        )}

        <YearPicker
          value={endYear}
          maxDate={maxDate ?? undefined}
          minDate={new Date(`${startYear + 1}`)}
          onChange={onSetEndYear}
          label={t('analytics_controls.end_year_picker_label')}
        />
      </div>

      <div className={`${CLASS_NAME}-right`}>
        <Dropdown
          options={lccOptions}
          value={selectedLCCOption}
          onChange={onChangeLCC}
          placeholder={t('analytics_controls.land_cover_dropdown_label')}
        />
      </div>
    </div>
  )
}

export { CLASS_NAME }
