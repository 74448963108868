import useClassName from '../../hooks/use_class_name'
import { DEFAULT_ICON_WIDTH_PX } from '../../config'

import './style.scss'

type Props = {
  className?: string
  width?: number
}

export default function IconKnowledgeBase({
  className,
  width = DEFAULT_ICON_WIDTH_PX
}: Props) {
  const finalClassName = useClassName(
    'cst-icon-knowledge-base',
    className,
    'cst-icon'
  )

  return (
    <div className={finalClassName}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={`${width}`}
        height={`${width}`}
        fill="none"
        viewBox="0 0 24 24"
      >
        <g stroke="#d4d4d4">
          <path
            stroke-width="2"
            d="M12 6.03v13m0-13c-2.819-.831-4.715-1.076-8.029-1.023A.99.99 0 0 0 3 6v11c0 .563.466 1.014 1.03 1.007 3.122-.043 5.018.212 7.97 1.023m0-13c2.819-.831 4.715-1.076 8.029-1.023A.99.99 0 0 1 21 6v11c0 .563-.466 1.014-1.03 1.007-3.122-.043-5.018.212-7.97 1.023"
          />
        </g>
      </svg>
    </div>
  )
}
