import { type Point } from 'geojson'
import { useEffect } from 'react'
import _isEmpty from 'lodash/isEmpty'
import _isUndefined from 'lodash/isUndefined'
import {
  type MapMouseEvent,
  type GeoJSONSource,
  type LngLatLike,
  type Map
} from 'mapbox-gl'

import * as C from '../const'

export default function useHandleClusterClick(map?: Map): void {
  useEffect((): (() => void) | undefined => {
    const onMapClick = (event: MapMouseEvent): void => {
      if (_isUndefined(map) || _isUndefined((map as any).style)) {
        return
      }

      const { point } = event
      const features = map.queryRenderedFeatures(point, {
        layers: [C.CLUSTERS_LAYER_ID, C.POINTS_LAYER_ID]
      })

      if (_isEmpty(features)) {
        return
      }

      const clusterId = features?.[0].id as number
      const source = map.getSource(C.FEATURE_POINTS_SOURCE) as GeoJSONSource

      source.getClusterExpansionZoom(clusterId, (err, zoom) => {
        if (err !== null) {
          return
        }

        map.flyTo({
          center: (features?.[0]?.geometry as Point)?.coordinates as LngLatLike,
          zoom
        })
      })
    }

    map?.on('click', C.CLUSTERS_LAYER_ID, onMapClick)

    return (): void => {
      map?.off('click', C.CLUSTERS_LAYER_ID, onMapClick)
    }
  }, [map])
}
