import { useContext, useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Trans } from 'react-i18next'
import cn from 'clsx'

import UIContext from 'ui_context'
import AuthContext from 'auth_context'
import { PATH as PATH_MAP } from 'pages/insights/map/const'
import { PATH as PATH_LOGIN } from 'pages/login/const'
import { PATH as PATH_ADMIN } from 'pages/admin/const'
import { PATH as PATH_ANALYTICS } from 'pages/insights/const'
import { PATH as PATH_DASHBOARD } from 'pages/dashboard/const'
import {
  DB_FEATURES_STORE_NAME,
  DB_EMISSIONS_STORE_NAME,
  RECENT_SUBS_STORAGE_KEY,
  DB_GEOMETRIES_STORE_NAME
} from 'config'

import IconNavDashboard from 'components/IconNavDashboard/IconNavDashboard'
import IconNavAnalytics from 'components/IconNavAnalytics/IconNavAnalytics'
import IconNavMap from 'components/IconNavMap/IconNavMap'
import IconSettings from 'components/IconSettings/IconSettings'
import IconLogout from 'components/IconLogout/IconLogout'
import IconKnowledgeBase from 'components/IconKnowledgeBase/IconKnowledgeBase'

import './style.scss'

export default function NavBar() {
  const navigate = useNavigate()
  const { setUser } = useContext(AuthContext)
  const { db, isUserAdmin } = useContext(UIContext)
  const { pathname: currentLocation } = useLocation()

  const handleIconNavMapClick = useCallback((): void => {
    navigate(PATH_MAP)
  }, [])

  const handleIconNavDashboardClick = useCallback((): void => {
    navigate(PATH_DASHBOARD[0])
  }, [])

  const handleIconNavAnalyticsClick = useCallback((): void => {
    navigate(PATH_ANALYTICS)
  }, [])

  const handleIconNavSettingsClick = useCallback((): void => {
    navigate(PATH_ADMIN)
  }, [])

  const isMapIconActive = currentLocation === PATH_MAP
  const isDashboardIconActive = PATH_DASHBOARD.includes(currentLocation)
  const isAnalyticsIconActive = currentLocation === PATH_ANALYTICS
  const isSettingsIconActive = currentLocation === PATH_ADMIN

  const handleLogoutClick = useCallback((): void => {
    if (db === null) return

    const run = async (): Promise<void> => {
      await db.clear(DB_FEATURES_STORE_NAME)
      await db.clear(DB_EMISSIONS_STORE_NAME)
      await db.clear(DB_GEOMETRIES_STORE_NAME)

      // Persist recent subs if the user is an admin
      if (isUserAdmin) {
        const recentSubsJSON = localStorage.getItem(RECENT_SUBS_STORAGE_KEY)
        let recentSubs = []

        try {
          recentSubs = JSON.parse(recentSubsJSON ?? '[]')
        } catch (err: any) {
          console.error(
            `Recent sub data in local storage is invalid JSON: ${err.message}`
          )
        }

        localStorage.clear()
        localStorage.setItem(
          RECENT_SUBS_STORAGE_KEY,
          JSON.stringify(recentSubs)
        )
      } else {
        localStorage.clear()
      }

      setUser(null)
      navigate(PATH_LOGIN)
    }

    // eslint-disable-next-line github/no-then
    run().catch((err: Error) => {
      console.error(err)
    })
  }, [isUserAdmin, db])

  return (
    <ul className="cst-nav-bar">
      <li
        onClick={handleIconNavDashboardClick}
        className={cn({ active: isDashboardIconActive })}
      >
        <IconNavDashboard active={isDashboardIconActive} />
        <p>
          <Trans i18nKey="nav_bar.nav_dashboard">Dashboard</Trans>
        </p>
      </li>
      <li
        onClick={handleIconNavAnalyticsClick}
        className={cn({ active: isAnalyticsIconActive })}
      >
        <IconNavAnalytics active={isAnalyticsIconActive} />
        <p>
          <Trans i18nKey="nav_bar.nav_analytics">Analytics</Trans>
        </p>
      </li>
      <li
        onClick={handleIconNavMapClick}
        className={cn({ active: isMapIconActive })}
      >
        <IconNavMap active={isMapIconActive} />
        <p>
          <Trans i18nKey="nav_bar.nav_map">Map</Trans>
        </p>
      </li>
      {isUserAdmin && (
        <li
          onClick={handleIconNavSettingsClick}
          className={cn({ active: isSettingsIconActive })}
        >
          <IconSettings active={isSettingsIconActive} />
          <p>
            <Trans i18nKey="nav_bar.nav_admin">Admin</Trans>
          </p>
        </li>
      )}
      <li onClick={handleLogoutClick}>
        <IconLogout />
        <p>
          <Trans i18nKey="nav_bar.nav_logout">Logout</Trans>
        </p>
      </li>
      <li>
        <a href="https://carbonspace.tech/knowledgebase" target="_blank">
          <IconKnowledgeBase />
          <p>
            <Trans i18nKey="nav_bar.knowledge_base">Knowledge Base</Trans>
          </p>
        </a>
      </li>
    </ul>
  )
}
