import _isUndefined from 'lodash/isUndefined'
import { type Map, type AnyLayer, type GeoJSONSource } from 'mapbox-gl'

import { type GeoJSON } from '../../../../types/data'
import { type CLUSTER_MAP_SOURCE_CONFIG } from '../const'

export function addMapSource(
  map: Map,
  id: string,
  data: GeoJSON,
  config?: typeof CLUSTER_MAP_SOURCE_CONFIG
): void {
  const existingSource: GeoJSONSource = map.getSource(id) as GeoJSONSource

  if (!_isUndefined(existingSource)) {
    existingSource.setData(data)
  } else {
    map.addSource(id, {
      generateId: true,
      type: 'geojson',
      data,
      ...config
    })
  }
}

export function addMapLayer(map: Map, data: AnyLayer, before?: string): void {
  const existingLayer = map?.getLayer(data.id)

  if (_isUndefined(existingLayer)) {
    map.addLayer(data, before)
  }
}

export function removeMapLayer(map: Map, data: AnyLayer): void {
  const existingLayer = map?.getLayer(data.id)

  if (!_isUndefined(existingLayer)) {
    map.removeLayer(data.id)
  }
}
