import React from 'react'
import { useWindowScroll } from '@uidotdev/usehooks'

import * as H from 'hooks'
import FixedContentHeader from 'components/FixedContentHeader/FixedContentHeader'

// TODO: Extract these as well
import AnalyticsControls from 'pages/insights/components/InsightsControls/InsightsControls'
import AnalyticsSideBarHeader from 'pages/insights/components/InsightsSidebarHeader/InsightsSidebarHeader'

import { CLASS_NAME } from './const'
import { type EmissionsFilterHeaderProps } from './types'

import './style.scss'

const EmissionsFilterHeader: React.FC<EmissionsFilterHeaderProps> = (
  props: EmissionsFilterHeaderProps
) => {
  const {
    title,
    filters,
    className,
    emissions,
    setFilterValue,
    emissionsMinDate,
    emissionsMaxDate
  } = props

  const [{ y: windowScrollY }] = useWindowScroll()
  const finalClassName = H.useClassName(CLASS_NAME, className)

  return (
    <FixedContentHeader
      isOverlapping={windowScrollY > 0}
      className={finalClassName}
    >
      <AnalyticsSideBarHeader
        filters={filters}
        setFilterValue={setFilterValue}
      />

      <div className={`${CLASS_NAME}-content-header`}>
        <h2>{title}</h2>

        <AnalyticsControls
          filters={filters}
          emissions={emissions}
          maxDate={emissionsMaxDate}
          minDate={emissionsMinDate}
          setFilterValue={setFilterValue}
        />
      </div>
    </FixedContentHeader>
  )
}

export default EmissionsFilterHeader
export { CLASS_NAME }
export type { EmissionsFilterHeaderProps as PROPS }
