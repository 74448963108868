import { useEffect } from 'react'
import { type Map, type AnyLayer } from 'mapbox-gl'
import _isUndefined from 'lodash/isUndefined'

import { addMapSource, addMapLayer } from '../utils/utils'
import {
  FEATURE_POINTS_SOURCE,
  FEATURES_SOURCE_ID,
  CLUSTERS_LAYER_CONFIG,
  POINTS_LAYER_CONFIG,
  CLUSTERS_LAYER_ID,
  CLUSTER_MAP_SOURCE_CONFIG,
  POLYGONS_LAYER_OPTIONS,
  POLYGONS_BORDERS_LAYER_OPTIONS,
  POINTS_LAYER_ID
} from '../const'

import { type GeoJSON } from '../../../../types/data'

export default function usePolygonsLayer(
  map: Map | undefined,
  geoJson: GeoJSON | null
) {
  useEffect(() => {
    if (
      geoJson === null ||
      _isUndefined(map) ||
      _isUndefined((map as any).style)
    ) {
      return
    }

    addMapSource(map, FEATURE_POINTS_SOURCE, geoJson, CLUSTER_MAP_SOURCE_CONFIG)
    addMapSource(map, FEATURES_SOURCE_ID, geoJson)

    addMapLayer(map, CLUSTERS_LAYER_CONFIG)
    addMapLayer(map, POINTS_LAYER_CONFIG as AnyLayer, CLUSTERS_LAYER_ID)

    addMapLayer(map, POLYGONS_LAYER_OPTIONS, POINTS_LAYER_ID)
    addMapLayer(map, POLYGONS_BORDERS_LAYER_OPTIONS, POINTS_LAYER_ID)
  }, [map, (map as any)?.style, geoJson])
}
