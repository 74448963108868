import * as H from 'hooks'
import PolygonCount from './PolygonCount/PolygonCount'
import TotalArea from './TotalArea/TotalArea'
import NEETotal from './NEETotal/NEETotal'
import NEEIntensity from './NEEIntensity/NEEIntensity'

import { CLASS_NAME } from './const'
import { type Emissions } from 'types/data'

import './style.scss'

export type Props = {
  emissions: Emissions[]
  year: number
  className?: string
}

export default function DashboardCards({
  year,
  emissions,
  className
}: Props): JSX.Element {
  const finalClassName = H.useClassName(CLASS_NAME, className)

  return (
    <div className={finalClassName}>
      <PolygonCount year={year} emissions={emissions} />
      <TotalArea emissions={emissions} />
      <NEETotal year={year} emissions={emissions} />
      <NEEIntensity year={year} emissions={emissions} />
    </div>
  )
}

export { CLASS_NAME }
