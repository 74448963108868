import _sum from 'lodash/sum'
import _isFinite from 'lodash/isFinite'
import { useTranslation } from 'react-i18next'

import * as H from 'hooks'
import * as U from 'utils'
import DashboardCard, { TextColor } from '../DashboardCard/DashboardCard'

import { CLASS_NAME } from './const'
import { type Emissions } from 'types/data'

import '../style.scss'

type Props = {
  emissions: Emissions[]
  year: number
  className?: string
}

export default function NEEIntensity({
  emissions,
  year,
  className
}: Props): JSX.Element {
  const { t } = useTranslation()
  const finalClassName = H.useClassName(CLASS_NAME, className)

  // prettier-ignore
  const totalArea = emissions === null
    ? 0
    : _sum(
      emissions.map(({ feature }) => {
        const { properties } = feature
        const { area } = properties

        return area ?? 0
      })
    )

  const emissionsTotalForYear = U.getEmissionsTotalForYear(
    emissions ?? [],
    year
  )

  // prettier-ignore
  const totalIntensity = emissions === null
    ? 0
    : totalArea === 0
      ? 0
      : _isFinite(emissionsTotalForYear)
        ? emissionsTotalForYear / totalArea
        : 0

  const finalValue = U.getFormattedEmissionsValue(totalIntensity, true)
  const total = U.getEmissionsTotalForYear(emissions, year)
  const getTextColor = (): TextColor | undefined => {
    if (total > 0) return TextColor.Red
    if (total < 0) return TextColor.Green
  }

  return (
    <DashboardCard
      title={t('metric_card_relative_flux.title')}
      tooltipText={t('metric_card_relative_flux.tooltip')}
      value={finalValue}
      textColor={getTextColor()}
      className={finalClassName}
    />
  )
}

export { CLASS_NAME }
